import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import PostHeader from '../PostHeader';

import './styles.scss';

const Blurb = ({ node }) => (
  <section className="Blurb blockLink">
    <Link to={node.fields.slug} className="noColor noUnderline">
      <PostHeader frontmatter={node.frontmatter} />
    </Link>
  </section>
);

Blurb.propTypes = {
  node: PropTypes.shape({}).isRequired,
};

export default Blurb;
