import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
// import Image from "../components/Image"
import Blurb from '../components/Blurb';

const IndexPage = ({ data }) => {
  const postList = data.allMarkdownRemark;
  return (
    <Layout>
      {
        postList.edges
          .map(({ node }) => <Blurb node={node} />)
          .reduce((acc, cur) => (
            <React.Fragment>
              {acc}
              <hr />
              {cur}
            </React.Fragment>
          ))
      }
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default IndexPage;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do, YYYY|DD/MM/YY")
            title
            series
            part
            description
          }
        }
      }
    }
  }
`;
